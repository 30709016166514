import { Tooltip, Tabs } from 'antd';
import moment from 'moment';
import { useImmer } from 'use-immer';
import CustomerStat from './CustomerStat';
import VisitorReviews from './VisitorReviews';

const SaaS = (props) => {
  const [saasActivePathTab, setSaasActivePathTab] = useImmer('imagetoolshub');
  const [activeTab, setActiveTab] = useImmer(1);

  return (
    <div style={{ padding: '4px 10px' }}>
      <Tabs
        defaultActiveKey={`${saasActivePathTab}`}
        onChange={(key) => setSaasActivePathTab(key)}
      >
        {/* stats */}
        <Tabs.TabPane tab="ImageTools Hub" key="imagetoolshub">
          {/* customer stat */}
        </Tabs.TabPane>
        {/* reviews */}
        <Tabs.TabPane tab="AudioTools Hub" key="audiotoolshub"></Tabs.TabPane>
      </Tabs>

      <Tabs
        defaultActiveKey={`${activeTab}`}
        onChange={(key) => setActiveTab(key)}
      >
        {/* stats */}
        <Tabs.TabPane tab="Customers" key="1">
          {/* customer stat */}
          {`${activeTab}` === '1' && (
            <CustomerStat saasActivePathTab={saasActivePathTab} />
          )}
        </Tabs.TabPane>
        {/* reviews */}
        <Tabs.TabPane tab="Reviews" key="2">
          {`${activeTab}` === '2' && (
            <VisitorReviews saasActivePathTab={saasActivePathTab} />
          )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default SaaS;

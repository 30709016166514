import { combineReducers } from 'redux';
import auth from './auth_reducer';
import config from './config_reducer';
// import lead from './lead_reducer';
// import myLead from './mylead_reducer';
// import user from './user_reducer';
// import job from './job_reducer';
import dashboard from './dashboard_reducer';
import ecom from './ecom_reducer';
import trade from './trade_reducer';
import saas from './saas_reducer';

const reducers = combineReducers({
  config,
  auth,
  dashboard,
  ecom,
  trade,
  saas,
});

export default reducers;

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Layout, Menu, Tooltip, Avatar, Button } from 'antd';
import {
  DashboardOutlined,
  LogoutOutlined,
  UserOutlined,
  FileSearchOutlined,
  BorderlessTableOutlined,
  ShopOutlined,
  LineChartOutlined,
  MenuOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
// import { MdCampaign } from 'react-icons/md';
import { useActions } from '../../hooks/useActions';
import { Outlet } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { useSelector } from 'react-redux';

const { SubMenu } = Menu;

const { Content, Sider } = Layout;

const SELECTED_MENU_KEY = '_selected_menu_hey';

// get children and render inside Content
const ParentLayout = () => {
  // redux states
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  //redux actions
  const { deleteAccessToken } = useActions();

  const [collapsed, setCollapsed] = useImmer(true);
  const [selectedMenuKey, setSelectedMenuKey] = useImmer('1');

  // toggle hide sidebar on mobile
  const [hideSidebar, setHideSidebar] = useImmer(true);

  const {
    login_user: { email, isAdmin },
  } = auth;

  const pathname = location.pathname;

  useEffect(() => {
    if (pathname.startsWith('/journal')) {
      setSelectedMenuKey('1');
    } else if (pathname.startsWith('/logs')) {
      setSelectedMenuKey('2');
    } else if (pathname.startsWith('/dashboard')) {
      setSelectedMenuKey('3');
    } else if (pathname.startsWith('/saas')) {
      setSelectedMenuKey('4');
    } else if (pathname.startsWith('/ecom')) {
      setSelectedMenuKey('5');
    } else if (pathname.startsWith('/crypto')) {
      setSelectedMenuKey('6');
    }
  }, [location.pathname]);
  //////////////////////////////////////////////
  useEffect(() => {
    try {
      const tempKey = localStorage.getItem(SELECTED_MENU_KEY);
      if (!tempKey) {
        return;
      }

      setSelectedMenuKey(tempKey);

      const ROUT_PATH = {
        1: '/journal',
        2: '/logs',
        3: '/dashboard',
        4: '/saas',
        5: '/ecom',
        6: '/crypto',
      };

      navigate(ROUT_PATH[Number(tempKey)]);
    } catch (err) {
      console.log('Error: ', err.message);
    }
  }, []);

  useEffect(() => {
    try {
      localStorage.setItem(SELECTED_MENU_KEY, selectedMenuKey);
    } catch (err) {
      console.log('Error: ', err.message);
    }
  }, [selectedMenuKey]);

  /////////////////////////////////////////////////
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  if (!isAdmin && pathname.startsWith('/users')) {
    navigate('/journal');
    return null;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Left Sider i.e. left side of Search box and Content i.e. Layout  */}

      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        theme="dark"
        width={'220'}
        trigger={window.innerWidth <= 600 ? null : ''}
        style={{ ...(hideSidebar && { display: 'none' }) }}
      >
        {/* User info */}
        <div
          style={{
            background: '#1890ff20',
            height: collapsed ? '40px' : '80px',
            display: collapsed ? '' : 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            cursor: 'default',
          }}
          className="testR1"
        >
          {/* Avatar and email */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              paddingTop: '4px',
            }}
          >
            <Avatar
              shape="square"
              size="default"
              src={
                <UserOutlined
                  style={{
                    color: '#1890ff',
                    fontSize: collapsed ? '24px' : '',
                  }}
                />
              }
              className="testO1"
            />
            <span
              style={{
                display: collapsed ? 'none' : '',
                cursor: 'default',
                color: '#1890ff',
              }}
            >
              <Tooltip title={email}>
                &nbsp;
                {email?.split('@')[0].substring(0, 15)}
                &nbsp;
              </Tooltip>
            </span>
          </div>
          {/* Logout */}
          <div
            onClick={deleteAccessToken}
            style={{
              display: collapsed ? 'none' : '',
              paddingLeft: '8px',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            <LogoutOutlined
              style={{
                cursor: 'pointer',
                color: '#1890ff',
              }}
            />
            <Button type="link" style={{ fontSize: '16px' }}>
              Logout
            </Button>
          </div>
        </div>

        {/* menu below User Name */}
        <Menu
          selectedKeys={[selectedMenuKey]}
          mode="inline"
          style={{
            borderRight: 'transparent',
            fontWeight: 'bold',
            marginTop: collapsed ? `210px` : `170px`,
          }}
          theme="dark"
        >
          {/* Journal */}
          <Menu.Item
            key="1"
            icon={
              <BorderlessTableOutlined
                style={{
                  fontSize: '24px',
                }}
              />
            }
          >
            <Link
              to="/journal"
              style={{
                fontSize: '16px',
              }}
            >
              Journal
            </Link>
          </Menu.Item>

          {/* Campaign */}
          {/* https://github.com/FortAwesome/Font-Awesome */}
          <Menu.Item
            key="2"
            icon={
              <FileSearchOutlined
                style={{
                  fontSize: '24px',
                }}
              />
            }
          >
            <Link
              to="/logs"
              style={{
                fontSize: '16px',
              }}
            >
              Logs
            </Link>
          </Menu.Item>

          {/* Dashboard */}
          <Menu.Item
            key="3"
            icon={<DashboardOutlined style={{ fontSize: '24px' }} />}
          >
            <Link to="/dashboard" style={{ fontSize: '16px' }}>
              Dashboard
            </Link>
          </Menu.Item>

          {/* Image Tools Hub */}
          <Menu.Item
            key="4"
            icon={<FileImageOutlined style={{ fontSize: '24px' }} />}
          >
            <Link to="/saas" style={{ fontSize: '16px' }}>
              SaaS
            </Link>
          </Menu.Item>

          {/* Dashboard */}
          <Menu.Item
            key="5"
            icon={<ShopOutlined style={{ fontSize: '24px' }} />}
          >
            <Link to="/ecom" style={{ fontSize: '16px' }}>
              Ecom Lead
            </Link>
          </Menu.Item>

          {/* Crypto */}
          <Menu.Item
            key="6"
            icon={<LineChartOutlined style={{ fontSize: '24px' }} />}
          >
            <Link to="/crypto" style={{ fontSize: '16px' }}>
              Crypto
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>

      {/* Right side of Sider i.e. Content */}
      <Layout>
        {/* Content */}
        <Content style={{ margin: '0px', background: 'white' }}>
          <div style={{ padding: '8px 0 0 8px' }}>
            <MenuOutlined
              onClick={() => {
                setHideSidebar(!hideSidebar);
              }}
              className="testR1"
              style={{ fontSize: '26px', cursor: 'pointer' }}
            />
          </div>

          <div
            style={{
              padding: '2px',
              height: '100%',
            }}
          >
            {/* children layout will go here */}
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ParentLayout;

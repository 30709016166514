import { Pagination, Table, Select, Tooltip, Tabs } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { useActions } from '../../hooks/useActions';
import { RedoOutlined } from '@ant-design/icons';
import CustomerStat from './CustomerStat';

const { Option } = Select;

const VisitorReviews = ({ saasActivePathTab }) => {
  const [sortByObj, setSortByObj] = useImmer(sortByArr[0]);

  const { getReviews } = useActions();
  const { page, reviews, total, limit } = useSelector((state) => state.saas);

  useEffect(() => {
    //get leads
    getReviewsNow();
  }, [sortByObj, saasActivePathTab]);

  const getReviewsNow = (
    mPage = page,
    mLimit = limit,
    dateFrom = undefined,
    dateTo = undefined,
    callback
  ) => {
    const query = `page=${mPage}&limit=${mLimit}&sort=${
      sortByObj.sort
    }&filter=${
      dateFrom && dateTo ? `dateFrom:${dateFrom},dateTo:${dateTo}` : ''
    }`;

    getReviews(query, saasActivePathTab, (success) => {
      success && callback && callback();
    });
  };

  // for sort by select, index starts from 0
  const onSortByChange = (index) => {
    console.log(index, sortByArr[index].sort);

    setSortByObj(sortByArr[index]);
  };

  return (
    <div style={{ padding: '4px 10px' }}>
      {/* reviews */}

      {/* total leads */}
      <div style={{ marginBottom: '12px' }}>
        <span> Total leads: {total}</span>

        {/* re-fetch v-leads */}
        <span
          onClick={() => getReviewsNow(page, limit)}
          style={{ cursor: 'pointer', marginLeft: '12px' }}
        >
          <Tooltip title="re-fetch Reviews">
            <RedoOutlined />
          </Tooltip>
        </span>
      </div>
      {/* pagination */}
      <div style={{ display: 'flex', margin: '0 0 22px 0' }}>
        <Pagination
          current={page}
          total={total}
          pageSize={limit}
          onChange={(page, pageSize) => {
            getReviewsNow(page, pageSize);
          }}
          showSizeChanger={true}
          style={{ fontSize: '16px' }}
          showQuickJumper
        />
      </div>
      {/* sort by & Date range */}
      <div style={{ display: 'flex' }}>
        {/* sort by */}
        <div
          className="testR1"
          style={{
            height: 'auto',
            fontWeight: 'bold',
            fontSize: '16px',
            marginRight: '24px',
          }}
        >
          Sort by:&nbsp;
          <Select
            value={sortByObj ? sortByObj.type : null}
            style={{ width: 240 }}
            onChange={onSortByChange}
          >
            {Array.isArray(sortByArr) &&
              sortByArr.map((itemObj, index) => (
                <Option key={index} value={index}>
                  {itemObj.type}
                </Option>
              ))}
          </Select>
        </div>
      </div>
      {/* v leads table */}
      <Table
        columns={COLUMNS}
        dataSource={reviews}
        rowKey="_id"
        style={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        }}
        pagination={false}
        size="large"
        showSorterTooltip={true}
        locale={{
          emptyText: !Array.isArray(reviews) && (
            <div style={{ fontWeight: 'bolder', fontSize: '22px' }}>
              Loading...
            </div>
          ),
        }}
      />
    </div>
  );
};

export default VisitorReviews;

const sortByArr = [
  {
    type: 'Rating Desc',
    sort: '-rating,-updatedAt',
  },

  {
    type: 'Rating Asc',
    sort: 'rating,-updatedAt',
  },

  { type: 'Newest Updated Date', sort: '-updatedAt' },
  { type: 'Oldest Updated Date', sort: 'updatedAt' },

  { type: 'Newest Created Date', sort: '-createdAt' },
  { type: 'Oldest Created Date', sort: 'createdAt' },
];

const COLUMNS = [
  {
    title: '_id',
    dataIndex: '_id',
    key: '_id',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 3 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 3)}
          {`${text}`.length > 3 && '..'}
        </span>
      </Tooltip>
    ),
  },
  {
    title: 'Feedback',
    dataIndex: 'feedback',
    key: 'feedback',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 25 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 25)}
          {`${text}`.length > 25 && '...'}
        </span>
      </Tooltip>
    ),
  },

  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
  },

  {
    title: 'Name',
    dataIndex: 'user_name',
    key: 'user_name',
    render: (text, record) => (
      <span>
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </span>
    ),
  },

  {
    title: 'User Email',
    dataIndex: 'user_email',
    key: 'user_email',
  },

  {
    title: 'User IP',
    dataIndex: 'user_ip',
    key: 'user_ip',
    render: (text, record) => (
      <>
        {text && (
          <a
            href={`https://whatismyipaddress.com/ip/${text}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${text}`}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'href',
    dataIndex: 'href',
    key: 'href',
    render: (text, record) => (
      <a href={text} rel="noopener noreferrer" target="_blank">
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </a>
    ),
  },

  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => <span>{moment(text).fromNow(true)} ago</span>,
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (text, record) => <span>{moment(text).fromNow(true)} ago</span>,
  },
];

import { Pagination, Table, Select, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { useActions } from '../../hooks/useActions';
import { RedoOutlined } from '@ant-design/icons';

const { Option } = Select;

const CustomerStat = ({ saasActivePathTab }) => {
  const [sortByObj, setSortByObj] = useImmer(sortByArr[0]);

  const { getCustomerStats } = useActions();

  const [customerStatObj, setCustomerStatObj] = useImmer({
    page: 1,
    customers: [],
    total: 0,
    limit: 0,
  });

  useEffect(() => {
    //get leads
    getCustomerStatsNow();
  }, [sortByObj, saasActivePathTab]);

  const getCustomerStatsNow = (
    mPage = customerStatObj.page,
    mLimit = customerStatObj.limit,
    dateFrom = undefined,
    dateTo = undefined,
    callback
  ) => {
    const query = `page=${mPage}&limit=${mLimit}&sort=${
      sortByObj.sort
    }&filter=${
      dateFrom && dateTo ? `dateFrom:${dateFrom},dateTo:${dateTo}` : ''
    }`;

    getCustomerStats(
      query,
      saasActivePathTab,
      (success, { page, customers, total, limit } = {}) => {
        if (!success) {
          return;
        }

        // console.log({ page, customers, total, limit });

        setCustomerStatObj({ page, customers, total, limit });
        callback && callback();
      }
    );
  };

  // for sort by select, index starts from 0
  const onSortByChange = (index) => {
    // console.log(index, sortByArr[index].sort);

    setSortByObj(sortByArr[index]);
  };

  return (
    <div style={{ padding: '4px 10px' }}>
      {/* total leads */}
      <div style={{ marginBottom: '12px' }}>
        <span> Total leads: {customerStatObj?.total || ''}</span>

        {/* re-fetch v-leads */}
        <span
          onClick={() =>
            getCustomerStatsNow(customerStatObj?.page, customerStatObj?.limit)
          }
          style={{ cursor: 'pointer', marginLeft: '12px' }}
        >
          <Tooltip title="re-fetch Reviews">
            <RedoOutlined />
          </Tooltip>
        </span>
      </div>
      {/* pagination */}
      <div style={{ display: 'flex', margin: '0 0 22px 0' }}>
        <Pagination
          current={customerStatObj?.page || 0}
          total={customerStatObj?.total || 0}
          pageSize={customerStatObj?.limit || 0}
          onChange={(page, pageSize) => {
            getCustomerStatsNow(page, pageSize);
          }}
          showSizeChanger={true}
          style={{ fontSize: '16px' }}
          showQuickJumper
        />
      </div>

      {/* sort by & Date range */}
      <div style={{ display: 'flex' }}>
        {/* sort by */}
        <div
          className="testR1"
          style={{
            height: 'auto',
            fontWeight: 'bold',
            fontSize: '16px',
            marginRight: '24px',
          }}
        >
          Sort by:&nbsp;
          <Select
            value={sortByObj ? sortByObj.type : null}
            style={{ width: 240 }}
            onChange={onSortByChange}
          >
            {Array.isArray(sortByArr) &&
              sortByArr.map((itemObj, index) => (
                <Option key={index} value={index}>
                  {itemObj.type}
                </Option>
              ))}
          </Select>
        </div>
      </div>

      {/* v leads table */}
      <Table
        columns={COLUMNS}
        dataSource={customerStatObj.customers}
        rowKey="_id"
        style={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        }}
        pagination={false}
        size="large"
        showSorterTooltip={true}
        locale={{
          emptyText: !Array.isArray(customerStatObj.customers) && (
            <div style={{ fontWeight: 'bolder', fontSize: '22px' }}>
              Loading...
            </div>
          ),
        }}
      />
    </div>
  );
};

export default CustomerStat;

const sortByArr = [
  { type: 'Newest Created Date', sort: '-createdAt' },
  { type: 'Oldest Created Date', sort: 'createdAt' },
  {
    type: 'Rating Desc',
    sort: '-rating,-updatedAt',
  },

  {
    type: 'Rating Asc',
    sort: 'rating,-updatedAt',
  },

  { type: 'Newest Updated Date', sort: '-updatedAt' },
  { type: 'Oldest Updated Date', sort: 'updatedAt' },
];

const COLUMNS = [
  {
    title: '_id',
    dataIndex: '_id',
    key: '_id',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 3 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 3)}
          {`${text}`.length > 3 && '..'}
        </span>
      </Tooltip>
    ),
  },

  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (text, record) => (
      <Tooltip title={`${text}`} placement="topLeft">
        <span>{moment(text).fromNow(true)} ago</span>
      </Tooltip>
    ),
  },

  {
    title: 'Stat',
    dataIndex: 'customer_stat',
    key: 'customer_stat',
    render: (text, record) => {
      let tempText = '';

      text &&
        Object.entries(text).map(([tool, count]) => {
          tempText += `${tool}:${count}, `;
        });

      return (
        <Tooltip
          title={`${tempText}`.length > 25 && `${tempText}`}
          placement="topLeft"
        >
          <span>
            {`${tempText}`.substring(0, 25)}
            {`${tempText}`.length > 25 && '...'}
          </span>
        </Tooltip>
      );
    },
  },

  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 12 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 12)}
          {`${text}`.length > 12 && '...'}
        </span>
      </Tooltip>
    ),
  },

  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => (
      <Tooltip title={`${text}`} placement="topLeft">
        <span>{moment(text).fromNow(true)} ago</span>
      </Tooltip>
    ),
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },

  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },

  {
    title: 'source_uuid',
    dataIndex: 'source_uuid',
    key: 'source_uuid',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 25 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 25)}
          {`${text}`.length > 25 && '...'}
        </span>
      </Tooltip>
    ),
  },
];
